import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_0594.jpg',
  'IMG_0616.jpg',
  'IMG_0721.jpg',
  'IMG_0741.jpg',
  'IMG_0808.jpg',
  'IMG_0832.jpg',
  'IMG_0918.jpg',
  'IMG_5711.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/family/firstB/${fileName}`);

const FirstBirthday: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default FirstBirthday;