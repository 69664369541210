import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_2482.jpg',
  'IMG_2499.jpg',
  'IMG_2528.jpg',
  'IMG_2538.jpg',
  'IMG_2546.jpg',
  'IMG_2662.jpg',
  'IMG_2670.jpg',
  'IMG_2693.jpg',
  'IMG_2733.jpg',
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/graduation/2021-08-14/${fileName}`);

const Aug21: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Aug21;