import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_6589.jpg',
  'IMG_6711.jpg',
  'IMG_6812.jpg',
  'IMG_6923-Enhanced-NR.jpg',
  'IMG_6953.jpg',
  'IMG_6956.jpg',
  'IMG_7079.jpg',
  'IMG_7169.jpg',
  'IMG_7301.jpg',
  'IMG_7423.jpg',
  'IMG_7438.jpg',
  'IMG_7613.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/graduation/2024-04-14/${fileName}`);

const Apr24: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Apr24;