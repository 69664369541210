import React from 'react';
import '../../App.css';

const imageFileNames = [
    'Lee-1.jpg',
    'Lee-2.jpg',
    'Lee-3.jpg',
    'Lee-4.jpg',
    'Lee-5.jpg',
    'Lee-6.jpg',
    'Lee-7.jpg',
    'Lee-8.jpg',
    'Lee-9.jpg',
    'Lee-10.jpg',
    'Lee-11.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/family/LeeFamily/${fileName}`);

const LeeFam: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default LeeFam;