import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  const covers = [
    { id: 1, image: 'https://lee.kevinsview.photography/assets/covers/grad.jpg', link: '/albumlist/GradList' },
    { id: 2, image: 'https://lee.kevinsview.photography/assets/covers/family.jpg', link: '/albumlist/FamilyList' },
    { id: 3, image: 'https://lee.kevinsview.photography/assets/covers/event.jpg', link: '/albumlist/EventList' },
    { id: 4, image: 'https://lee.kevinsview.photography/assets/covers/street.jpg', link: '/albumlist/StreetList' },
    { id: 5, image: 'https://lee.kevinsview.photography/assets/covers/etc.jpg', link: '/albumlist/etc' },
    // Add more albums as needed
  ];

  return (
    <div className="album-list">
      {covers.map(cover => (
        <div key={cover.id} className="cover">
          <Link to={cover.link}>
            <img src={cover.image} alt={`Cover ${cover.id}`} />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Home;