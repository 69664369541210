import React from 'react';
import '../App.css';

const Contact: React.FC = () => {
  return (
    <div className="contact">
      <h1>Contact</h1>
      <p>
        If you have any questions, want to discuss a photography project, or simply want to say hello,
        feel free to reach out. I’m excited to connect with you and help capture your special moments.
      </p>
      <p>
        <br />
        <b>Kevin K. Lee</b> <br />
        <b>Location:</b> Ann Arbor, MI <br />
        <b>Email:</b> lksung@umich.edu <br />
        <b>Text:</b> (734) 645 - 4801 <br />
        <b className='alert'>*Please text me first. I don't answer the calls from unknown numbers </b><br /><br />
      </p>
      <p>
        I look forward to hearing from you!
      </p>
    </div>
  );
};

export default Contact;