import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_1138.jpg',
  'IMG_1148.jpg',
  'IMG_1166.jpg',
  'IMG_1207-Edit.jpg',
  'IMG_1215.jpg',
  'IMG_1234.jpg',
  'IMG_1251.jpg',
  'IMG_1262.jpg',
  'IMG_1263.jpg',
  'IMG_1281-2.jpg',
  'IMG_1296.jpg',
  'IMG_1314.jpg',
  'IMG_1323.jpg',
  'IMG_1415.jpg',
  'IMG_1443-Edit.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/event/wedding/${fileName}`);

const Wedding: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Wedding;