import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Adjust this path if your CSS is located elsewhere

const StreetList: React.FC = () => {
  const albums = [
    { id: 1, image: 'https://lee.kevinsview.photography/assets/StreetThumbnail/406A0019.jpg', description: 'AA Downtown', link: '/albumlist/StreetShots/a2downtown' },
    { id: 2, image: 'https://lee.kevinsview.photography/assets/StreetThumbnail/IMG_0074.jpg', description: 'Niagara Falls', link: '/albumlist/StreetShots/niagara' },
    { id: 3, image: 'https://lee.kevinsview.photography/assets/StreetThumbnail/GH-4.jpg', description: 'Grand Haven', link: '/albumlist/StreetShots/Grandhaven' }
  ];

  return (
    <div className="album-list">
      {albums.map(album => (
        <div key={album.id} className="album">
          <Link to={album.link} className='album-link'>
            <img src={album.image} alt={`Album ${album.description}`} />
            <div className="album-description">{album.description}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default StreetList;