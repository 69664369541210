import React from 'react';
import '../../App.css';

// List of image file names in your S3 bucket's /img/etc/ directory
const imageFileNames = [
  'GH-1.jpg',
  'GH-2.jpg',
  'GH-3.jpg',
  'GH-4.jpg',
  'GH-5.jpg',
  'GH-6.jpg'
  // Add all other image file names here
];

// Your CloudFront distribution domain name
const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/street/Grand Haven/${fileName}`);

const GH: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default GH;