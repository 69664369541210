import React from 'react';
import '../../App.css';

// List of image file names in your S3 bucket's /img/etc/ directory
const imageFileNames = [
  '406A0008.jpg',
  '406A0011.jpg',
  '406A0019.jpg',
  '406A0022.jpg',
  '406A0027.jpg',
  '406A0037.jpg',
  '406A0041.jpg',
  '406A0045.jpg',
  '406A0061.jpg',
  '406A0062.jpg',
  '406A0073.jpg',
  '406A0076.jpg',
  '406A0084.jpg',
  '406A0100.jpg'

  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/street/A2downtown/${fileName}`);

const A2downtown: React.FC = () => {
  return (
    <div className="App-body">
    <div className="image-gallery">
      {imageList.map((image) => {
        const fileName = image.split('/').pop() as string;
        return (
          <img key={fileName} src={image} alt={fileName} />
        );
      })}
    </div>
  </div>
  );
};

export default A2downtown;