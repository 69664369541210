import React from 'react';
import '../App.css';

const About: React.FC = () => {
  return (
    <div className="about-grid">
      <p>
        Welcome to my website! My name is Kevin. I'm a Korean American and speak both 
        Korean and English (and little bit of Japanese too!). I’ve been passionate about 
        photography since my senior year of high school. My journey began with a Canon EOS Rebel T3i, 
        a gift from my dad’s friend. Initially, I was captivated by the art of capturing 
        moments with my camera. Over time, especially through portrait photography, <br />
        I discovered the joy of bringing smiles to others with my pictures. <br />
        This realization has made my work feel incredibly rewarding.
      </p>
      <p>
        Originally from Ann Arbor, MI, I am available to travel throughout Michigan to 
        capture special moments for you and get to know you better. Photography is not 
        just a job for me; it's a way to spread happiness and create lasting memories. 
      </p>
      <p>
        Feel free to reach out with any questions or to discuss your photography needs. <br />
        I look forward to working with you!
      </p>
      <div>
        <img src='https://lee.kevinsview.photography/assets/about.jpg' alt='my selfie' />
      </div>
    </div>
  );
};

export default About;