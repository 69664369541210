import React from 'react';
import '../../App.css';

const imageFileNames = [
  'mary1.jpg',
  'mary2.jpg',
  'mary3.jpg',
  'mary4.jpg',
  'mary5.jpg',
  'mary6.jpg',
  'mary7.jpg',
  'mary8.jpg',
  'mary9.jpg',
  'mary10.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/graduation/2024-08-10/${fileName}`);

const Aug22: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Aug22;