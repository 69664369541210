import React from 'react';
import '../../App.css';

// List of image file names in your S3 bucket's /img/etc/ directory
const imageFileNames = [
  'IMG_0055.jpg',
  'IMG_0061.jpg',
  'IMG_0062.jpg',
  'IMG_0063.jpg',
  'IMG_0074.jpg',
  'IMG_0078.jpg',
  'IMG_0087.jpg',
  'IMG_0088.jpg',
  'IMG_0089.jpg',
  'IMG_0093.jpg',
  'IMG_0094.jpg',
  'IMG_0095.jpg',
  'IMG_0128.jpg',
  'IMG_0203-Enhanced-NR.jpg',
  'IMG_0204-Enhanced-NR.jpg'
  // Add all other image file names here
];

// Your CloudFront distribution domain name
const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/street/niagara/${fileName}`);

const Niagara: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Niagara;