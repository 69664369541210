import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Adjust this path if your CSS is located elsewhere

const FamilyList: React.FC = () => {
  const albums = [
    { id: 1, image: 'https://lee.kevinsview.photography/assets/FamilyThumbnail/IMG_0594.jpg', description: 'Baby\'s First Birthday', link:'/albumlist/Family/FirstBirthday' },
    { id: 2, image: 'https://lee.kevinsview.photography/assets/FamilyThumbnail/406A1428.jpg', description: 'So Family', link: '/albumlist/Family/SoFamily' },
    { id: 3, image: 'https://lee.kevinsview.photography/assets/FamilyThumbnail/Lee-1.jpg', description: 'Lee Family', link: '/albumlist/Family/LeeFamily' },
    { id: 4, image: 'https://lee.kevinsview.photography/assets/FamilyThumbnail/Shine-6.jpg', description: 'Hwang Family', link: '/albumlist/Family/HwangFamily' },
  ];

  return (
    <div className="album-list">
      {albums.map(album => (
        <div key={album.id} className="album">
          <Link to={album.link} className='album-link'>
            <img src={album.image} alt={`Album ${album.description}`} />
            <div className="album-description">{album.description}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FamilyList;