import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Adjust this link if your CSS is located elsewhere
 // Add links to album images

const GradList: React.FC = () => {
  const albums = [
    { id: 1, image: 'https://lee.kevinsview.photography/assets/GradThumbnail/mary7.jpg', link:'/albumlist/Graduations/Aug24' },
    { id: 2, image: 'https://lee.kevinsview.photography/assets/GradThumbnail/IMG_7301.jpg', link:'/albumlist/Graduations/Apr24' },
    { id: 3, image: 'https://lee.kevinsview.photography/assets/GradThumbnail/IMG_5666-Enhanced-NR.jpg', link:'/albumlist/Graduations/Jun23' },
    { id: 4, image: 'https://lee.kevinsview.photography/assets/GradThumbnail/IMG_4254.jpg', link:'/albumlist/Graduations/Aug22' },
    { id: 5, image: 'https://lee.kevinsview.photography/assets/GradThumbnail/IMG_2538.jpg', link:'/albumlist/Graduations/Aug21' },
    { id: 6, image: 'https://lee.kevinsview.photography/assets/GradThumbnail/IMG_9938.jpg', link:'/albumlist/Graduations/Mar20' },
    // Add more albums as needed
  ];

  return (
    <div className="album-list">
      {albums.map(album => (
        <div key={album.id} className="album">
          <Link to={album.link} className='album-link'>
            <img src={album.image} alt={`Album ${album.id}`} />
            <div className="album-description"></div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default GradList;