import React, { useEffect } from "react";
import '../App.css';

const Mission: React.FC = () => {
  useEffect(() => {
    // If the script is already loaded, initialize the button directly
    if (!document.querySelector('#donate-button img')) {
      // @ts-ignore
      PayPal.Donation.Button({
        env: 'production',
        hosted_button_id: '4S8UHNAQ8F6T4',
        image: {
          src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
          alt: 'Donate with PayPal button',
          title: 'PayPal - The safer, easier way to pay online!',
        }
      }).render('#donate-button');
    }
  }, []);

  const goToGoFundMe = () => {
    window.open('https://gofund.me/bd87af79', '_blank');
  }

  return (
    <div className="mission">
      <div className="statement">
        <h1>My Vision and Mission</h1>
        <p>
          Capturing the inherent beauty of people and the special moments that define their lives is my passion as a photographer. 
          However, I have noticed a decline in the demand for professional photo sessions, perhaps due to an increasing reliance on 
          digital storage and the diminishing presence of professional studios and printing services. This shift has led to fewer 
          people prioritizing the preservation of their precious memories through high-quality photographs.
        </p>
        <p>
          My mission is to bridge this gap and bring the joy of sharing and the beauty of remembering to those who might otherwise 
          be unable to afford it. To achieve this, I aim to visit various places and host family or group photo sessions for those 
          who don't have the time, money, or resources to do so themselves. By offering these services, I hope to provide a means 
          for everyone to capture and cherish their special moments, ensuring that no one is denied the opportunity to preserve and 
          celebrate the beauty of their lives.
        </p>
        <p>
          To achieve this, I need your support. Financial donations, regardless of size, will be carefully saved and allocated toward this mission. 
          I am committed to using the resources I have and the skills I possess to positively impact others and bring happiness into their lives. 
          All of my work and progress will be documented and made available on my website, ensuring complete transparency and 
          allowing you to witness the positive impact of your contributions.
        </p>
        <p>
          Currently, as a college student, my immediate capacity to initiate this endeavor is limited. However, rest assured that 
          once I am in a position to fully dedicate myself, this project will commence in earnest. Your interest and support are deeply 
          appreciated and will play a crucial role in turning this vision into reality.
        </p>
        <p>
          With much gratitude and a heart full of hope, <br />
          Much love and peace to you.
        </p>
      </div>
      <div className="donation">
        Future Mission Donation
        <div id="donate-button"/>
      </div>
    </div>
  );
};

export default Mission;