import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_0017.jpg',
  'IMG_0031.jpg',
  'IMG_0039.jpg',
  'IMG_0048.jpg',
  'IMG_0102.jpg',
  'IMG_0111.jpg',
  'IMG_0125.jpg',
  'IMG_0159.jpg',
  'IMG_9938.jpg',
  'IMG_9979.jpg'
// Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/graduation/2020-03-21/${fileName}`);

const Mar20: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Mar20;