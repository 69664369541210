import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_4042.jpg',
  'IMG_4055.jpg',
  'IMG_4065.jpg',
  'IMG_4070.jpg',
  'IMG_4134.jpg',
  'IMG_4137.jpg',
  'IMG_4153.jpg'
// Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/event/model/${fileName}`);

const ModelApp: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default ModelApp;