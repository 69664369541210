import React from 'react';
import '../../App.css';

const imageFileNames = [
  '406A1328.jpg',
  // '406A1330.jpg',
  '406A1335.jpg',
  '406A1338.jpg',
  // '406A1341.jpg',
  '406A1343.jpg',
  '406A1351.jpg',
  '406A1363.jpg',
  '406A1395.jpg',
  '406A1404.jpg',
  '406A1408.jpg',
  '406A1418.jpg',
  '406A1424.jpg',
  '406A1428.jpg',
  '406A1434.jpg',
  '406A1441.jpg',
  '406A1456.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/family/SoFamily/${fileName}`);

const FirstBirthday: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default FirstBirthday;