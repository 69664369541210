import React from 'react';
import '../../App.css';

const imageFileNames = [
  'IMG_4708.jpg',
  'IMG_4721.jpg',
  'IMG_4738.jpg',
  'IMG_4741.jpg',
  'IMG_4745.jpg',
  'IMG_4754.jpg',
  'IMG_4773.jpg',
  'IMG_4812.jpg',
  'IMG_4843.jpg',
  'IMG_4885.jpg',
  'IMG_4894.jpg',
  'IMG_4985.jpg',
  'IMG_5008.jpg',
  'IMG_5022.jpg',
  'IMG_5062.jpg',
  'IMG_5171.jpg',
  'IMG_5203.jpg',
  'IMG_5218.jpg',
  'IMG_5232.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/event/hillsong/${fileName}`);

const Hillsong: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((img) => {
          const fileName = img.split('/').pop() as string;
          return (
            <img key={fileName} src={img} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Hillsong;
