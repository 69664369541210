import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import Home from './tab/Home';
import About from './tab/About';
import Mission from './tab/Mission';
import Contact from './tab/Contact';
import GradList from './albumlist/GradList';
import Mar20 from './albumlist/Graduations/Mar20';
import Apr24 from './albumlist/Graduations/Apr24';
import Jun23 from './albumlist/Graduations/Jun23';
import Aug21 from './albumlist/Graduations/Aug21';
import Aug22 from './albumlist/Graduations/Aug22';
import Aug24 from './albumlist/Graduations/Aug24';
import EventList from './albumlist/EventList';
import Hillsong from './albumlist/Events/Hillsong';
import ModelApp from './albumlist/Events/ModelApp';
import Rangeela from './albumlist/Events/Rangeela';
import Wedding from './albumlist/Events/Wedding';
import FamilyList from './albumlist/FamilyList'
import FirstB from './albumlist/Family/FirstBirthday';
import SoFam from './albumlist/Family/SoFamily';
import LeeFam from './albumlist/Family/LeeFamily';
import HwangFam from './albumlist/Family/HwangFamily';
import StreetList from './albumlist/StreetList';
import A2downtown from './albumlist/StreetShots/a2downtown';
import Niagara from './albumlist/StreetShots/niagara';
import GH from './albumlist/StreetShots/GrandHaven';
import Etc from './albumlist/etc';

const App: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  return (
    <Router>
      <div className="App">
        {/* Header */}
        <header className="App-header">
          <div className="header-left">
            <Link to="/"><img src='https://lee.kevinsview.photography/assets/logo.png' alt='Logo' /></Link>
          </div>
          <div className="header-center">
            <div>
              <button onClick={toggleDropdown} className="dropdown-toggle">
                <FontAwesomeIcon icon={dropdownOpen ? faTimes : faBars} /> {/* Conditionally render icon */}
              </button>
              {dropdownOpen && (
                <nav className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                  <ul>
                    <li><Link to="/" onClick={toggleDropdown}>Home</Link></li>
                    <li><Link to="/about" onClick={toggleDropdown}>About</Link></li>
                    <li><Link to="/mission" onClick={toggleDropdown}>Mission</Link></li>
                    <li><Link to="/contact" onClick={toggleDropdown}>Contact</Link></li>
                  </ul>
                </nav>
              )}
            </div>
            <div>
              <Link to="/"><img src='https://lee.kevinsview.photography/assets/logo.png' alt='Logo' /></Link>
            </div>
          </div>
          <div className="header-right">
            <nav>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/mission">Mission</Link></li>
                <li><Link to="/contact">Contact</Link></li>
              </ul>
            </nav>
          </div>
        </header>

        {/* Routes */}
        <div className="App-content"> {/* New div with App-content class */}
          <div className='body-margin'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/contact" element={<Contact />} />

              {/* Graduation List */}
              <Route path="/albumlist/GradList" element={<GradList />} />
              <Route path="/albumlist/Graduations/Mar20" element={<Mar20 />} />
              <Route path="/albumlist/Graduations/Apr24" element={<Apr24 />} />
              <Route path="/albumlist/Graduations/Jun23" element={<Jun23 />} />
              <Route path="/albumlist/Graduations/Aug21" element={<Aug21 />} />
              <Route path="/albumlist/Graduations/Aug22" element={<Aug22 />} />
              <Route path="/albumlist/Graduations/Aug24" element={<Aug24 />} />


              {/* Family List */}
              <Route path="/albumlist/FamilyList" element={<FamilyList />} />
              <Route path="/albumlist/Family/FirstBirthday" element={<FirstB />} />
              <Route path="/albumlist/Family/SoFamily" element={<SoFam />} />
              <Route path="/albumlist/Family/LeeFamily" element={<LeeFam />} />
              <Route path="/albumlist/Family/HwangFamily" element={<HwangFam />} />

              {/* Event List */}
              <Route path="/albumlist/EventList" element={<EventList />} />
              <Route path="/albumlist/Events/Hillsong" element={<Hillsong />} />
              <Route path="/albumlist/Events/ModelApp" element={<ModelApp />} />
              <Route path="/albumlist/Events/Rangeela" element={<Rangeela />} />
              <Route path="/albumlist/Events/Wedding" element={<Wedding />} />

              {/* Street Shots List */}
              <Route path="/albumlist/StreetList" element={<StreetList />} />
              <Route path="/albumlist/StreetShots/a2downtown" element={<A2downtown />} />
              <Route path="/albumlist/StreetShots/niagara" element={<Niagara />} />
              <Route path="/albumlist/StreetShots/GrandHaven" element={<GH />} />

              {/* Etc. */}
              <Route path="/albumlist/etc" element={<Etc />} />
            </Routes>
          </div>
        </div>

        {/* Footer */}
        <footer className="App-footer">
          <div className="footer-icons">
            <a href="https://www.instagram.com/k.s_ansi/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className='footer-icon' />
            </a>
            <a href="mailto:lksung@umich.edu">
              <FontAwesomeIcon icon={faEnvelope} className='footer-icon' />
            </a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;