import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Adjust this link if your CSS is located elsewhere

const EventList: React.FC = () => {
   const albums=[
    { id: 1, image: 'https://lee.kevinsview.photography/assets/EventThumbnail/IMG_4894.jpg', description: 'Hillsong United: People Tour', link:'/albumlist/Events/Hillsong' },
    { id: 2, image: 'https://lee.kevinsview.photography/assets/EventThumbnail/IMG_4055.jpg', description: 'Model Application', link:'/albumlist/Events/ModelApp' },
    { id: 3, image: 'https://lee.kevinsview.photography/assets/EventThumbnail/IMG_8942.jpg', description: 'Rangeela Showcase at Calvin University', link:'/albumlist/Events/Rangeela' },
    { id: 4, image: 'https://lee.kevinsview.photography/assets/EventThumbnail/IMG_1296.jpg', description: 'Wedding', link:'/albumlist/Events/Wedding' },
   ];

   return (
    <div className="album-list">
      {albums.map(album => (
        <div key={album.id} className="album">
          <Link to={album.link} className='album-link'>
            <img src={album.image} alt={`Album ${album.id}`} />
            <div className="album-description">{album.description}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default EventList;