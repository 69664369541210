import React from 'react';
import '../App.css';

// List of image file names in your S3 bucket's /img/etc/ directory
const imageFileNames = [
  'fireworks on july 4th.jpg',
  'IMG_1861.jpg',
  'IMG_1939.jpg',
  'IMG_1963.jpg',
  'IMG_1968.jpg',
  'IMG_3041.jpg',
  'IMG_3047.jpg'
  // Add all other image file names here
];

const cloudFrontDomain = 'https://lee.kevinsview.photography';

const imageList: string[] = imageFileNames.map(fileName => `${cloudFrontDomain}/img/etc/${fileName}`);

const Etc: React.FC = () => {
  return (
    <div className="App-body">
      <div className="image-gallery">
        {imageList.map((image) => {
          // Extract the file name just for alt text purposes
          const fileName = image.split('/').pop() as string;
          return (
            <img key={fileName} src={image} alt={fileName} />
          );
        })}
      </div>
    </div>
  );
};

export default Etc;